import { connect } from "react-redux";
import { useRef } from "react";

function URLPreview({ previewFor, url, description, image, title }) {

    const imgRef = useRef(null);

    function handlePreviewClicked() {
        if (previewFor === "createPosting") {
            window.open(url, '_blank');
        }
    }

    function handleImgOnError() {
        imgRef.current.src = "/img/image_not_found.jpeg";
    }

    return (
        <div className={`url-preview-${previewFor} card`} onClick={handlePreviewClicked}>
            {image !== undefined && image !== "" &&
                <img className="card-img-top url-preview-image" src={image} ref={imgRef} onError={handleImgOnError} alt="..."></img>
            }
            <div className="card-body">
                {title !== undefined && title !== "" &&
                    <h5 className="card-title">{title}</h5>
                }
                {description !== undefined && description !== "" &&
                    <p className="card-text">{description}</p>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(URLPreview);
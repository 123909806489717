import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Login from "../components/welcome/Login";
import Signup from "../components/welcome/Signup";

function Welcome() {
    const location = useLocation();
    return (
        <>
            <h1 className="col-sm-12" align="center" style={{ marginTop: "15px" }}>Welcome to Iris!</h1>
            <div className="col-sm-12">
                <Link to="/login">
                    <Button className="form-control" style={{ width: "80%", position: "relative", left: "50%", transform: "translate(-50%, 0%)", marginTop: "50px" }}>
                        Login
                    </Button>
                </Link>
                <Link to="/signup">
                    <Button className="form-control" style={{ width: "80%", position: "relative", left: "50%", transform: "translate(-50%, 0%)", marginTop: "25px" }}>
                        Sign up
                    </Button>
                </Link>
            </div>
            {location.pathname !== "/" ? (location.pathname === "/login" ? <Login /> : <Signup />) : ""}
        </>
    );
}

export default (Welcome);
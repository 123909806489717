// set state in the store to default values
const initial = {
    user_info: {
        user_id: "999",
        username: "tester000",
        email: "tester0@test.com",
        pro_pic: "",
        password_count: 4
    },
};

export default initial;
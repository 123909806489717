import SearchMenuItem from "./SearchMenuItem";

function SearchPage({ searchText, searchResultsUsers, searchResultsInterests }) {

    return (
        <div className="search-page">
            <h5>Search results for "{searchText}"</h5>
            {searchResultsInterests.length > 0 &&
                <>
                    <h5>Posts</h5>
                    {searchResultsInterests.map((interest) => (
                        <h6 key={interest.interest_id} className="search-page-item">{interest.interest_name}</h6>
                    ))}
                </>
            }
            {searchResultsUsers.length > 0 &&
                <>
                    <h5>People</h5>
                    {searchResultsUsers.map((user) => (
                        <SearchMenuItem user={user} />
                    ))}
                </>
            }
            {searchResultsInterests.length === 0 && searchResultsUsers.length === 0 &&
                <h5>no results found</h5>
            }
        </div>
    );
}

export default SearchPage;
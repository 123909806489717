import { connect } from "react-redux";

function Home({ user_info }) {

    console.log("home : userinfo ", user_info);

    // const navigate = useNavigate();

    return (
        <div className="cs">

            <span className="tes">home feed</span>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(Home);
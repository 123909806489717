import { connect } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import SearchMenuItem from "../components/search/SearchMenuItem";
import SearchPage from "../components/search/SearchPage";
import { getSearch } from "../api/search";

function Search() {

    // search should search : 1) people, 2) topics/posts (w/ topic keyword)

    // search should have a filter button/icon to filter the search, (or changes the search from people to posts)

    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsUsers, setSearchResultsUsers] = useState([]);
    const [searchResultsInterests, setSearchResultsInterests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const [searchText, setSearchText] = useState("");
    const [showSearchPage, setShowSearchPage] = useState(false);
    const typeaheadRef = useRef(null);
    const fromRef = useRef(null);

    async function handleAutoSearch(q, onEnterPress) {
        setShowSearchPage(onEnterPress);
        setIsLoading(true);
        let resp = await getSearch(q, "users");
        resp = resp.data;
        if (onEnterPress) {
            setSearchResultsUsers(resp.search_results);
        } else {
            setSearchResults(resp.search_results);
        }
        setIsLoading(false);
    }

    async function handleSearchInterests(text) {
        let resp = await getSearch(text, "interests");
        console.log("resp (interests) : ", resp);
        setSearchResultsInterests(resp.data.search_results);
    }

    const handleSearchEnter = useCallback(
        (e) => {
            if (e.keyCode === 13 && activeIndex === -1) {
                setSearchResults([]);
                typeaheadRef.current.clear();
                setShowSearchPage(true);
                handleAutoSearch(searchText, true);
                handleSearchInterests(searchText);
            }
        },
        [activeIndex, searchText]
    );

    const ActiveIndexWatcher = ({ update }) => {
        useEffect(update);
        return null;
    };

    return (
        <div>
            <h3>Search</h3>
            <AsyncTypeahead
                id={"search-typeahead"}
                ref={typeaheadRef}
                labelKey={"username"}
                placeholder={"Search"}
                minLength={1}
                delay={50}
                promptText={"Press enter to search posts..."}
                isLoading={isLoading}
                options={searchResults}
                onSearch={handleAutoSearch}
                onInputChange={(text) => {
                    setSearchText(text);
                    fromRef.current = text;
                    handleAutoSearch(text, false);
                }}
                onKeyDown={handleSearchEnter}
                renderMenuItemChildren={(user) => (
                    <SearchMenuItem
                        user={user}
                    />
                )}
            />
            <ActiveIndexWatcher update={() => setActiveIndex(activeIndex)} />
            {showSearchPage &&
                <SearchPage
                    searchText={searchText}
                    searchResultsUsers={searchResultsUsers}
                    searchResultsInterests={searchResultsInterests}
                />
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(Search);
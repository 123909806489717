import { connect } from "react-redux";
import { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { setUserInfo } from "../redux/actions";
import { changeSettings } from "../api/settings";

function Settings({ user_info, dispatch }) {

    const [email, setEmail] = useState(user_info.email);
    const [password, setPassword] = useState("");
    const [passwordChanged, setPasswordChanged] = useState(false);
    const [proPic, setProPic] = useState(user_info.pro_pic !== "" ? "data:image/jpeg;base64," + user_info.pro_pic : "/img/default_pro_pic.png");
    const [proPicParam, setProPicParam] = useState("");
    const [saveClickedTemp, setSaveClickedTemp] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const inputRef = useRef(null);

    async function saveSettings() {
        var emailParam = "";
        if (email !== user_info.email) {
            emailParam = email;
            var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (!email.trim().match(validEmailRegex)) {
                alert("Email is not valid");
                return;
            }
        }
        if (passwordChanged && password === "") {
            alert("Password cannot be blank");
            return;
        }
        var passwordParam = passwordChanged ? password : "";
        if (emailParam === "" && passwordParam === "" && proPicParam === "") return; // nothing changed but save clicked

        let resp = await changeSettings(user_info.user_id, emailParam, passwordParam, proPicParam);
        resp = resp.data;
        if (resp.result === "success") {
            let updatedUserInfo = resp.user_info;
            dispatch(setUserInfo(updatedUserInfo));
            setSaveClickedTemp(true);
            setTimeout(() => {
                setSaveClickedTemp(false);
            }, 3000);
            setProPicParam("");
            setPassword("");
            setPasswordChanged(false);
        } else if (resp.result === "duplicate_email") {
            setSaveError(true);
            setTimeout(() => {
                setSaveError(false);
            }, 3000);
        }
    }

    function handleChangeProPic(e) {
        if (e.target.files.length === 0) return;
        if (e.target.files[0].size >= 10000000) {
            alert("File too large, under 10MB required");
            return;
        }
        var reader = new FileReader();
        reader.onload = function (event) {
            setProPic(event.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        console.log('set propic[arm]');
        setProPicParam(e.target.files[0]);
    }

    var passHidden = "";
    for (let i = 0; i < user_info.password_count; i++) {
        passHidden += "*";
    }

    return (
        <div>
            <h3>Settings</h3>
            <br />
            <div>
                <img
                    className="settings-pro-pic"
                    // src={user_info.pro_pic !== "" ? "data:image/jpeg;base64," + user_info.pro_pic : "/img/default_pro_pic.png"}
                    src={proPic}
                    alt=""
                    onClick={() => inputRef.current.click()}
                ></img>
                <label style={{ position: "relative", transform: "translateY(25%)", left: "10px" }}>
                    {user_info.username}
                    <br />
                    <button className="link-primary change-pro-pic" onClick={() => inputRef.current.click()}>
                        Change Profile Picture
                    </button>
                </label>
                <input
                    type="file"
                    style={{ display: "none" }}
                    ref={inputRef}
                    accept="image/*"
                    onChange={handleChangeProPic}
                />
            </div>
            <br />
            <h6>Change email/password:</h6>
            <div>
                <label>Email: </label><br />
                <input className="form-control" type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={user_info.email}
                />
            </div>
            <br />
            <div>
                <label>Password: </label><br />
                <input className="form-control" type="password"
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPasswordChanged(true);
                    }}
                    placeholder={passHidden}
                />
            </div>
            <br />
            <Button onClick={saveSettings} style={{ width: "100%" }}>
                Save
            </Button>
            {saveClickedTemp && <span style={{ color: "green" }}>Settings saved.</span>}
            {saveError && <span style={{ color: "red" }}>Save failed. Email already exists.</span>}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(Settings);
// import { useState } from "react";
// import { useNavigate } from "react-router-dom";

function Notifications() {

    // const navigate = useNavigate();

    return (
        <h3>Notifications</h3>
    );
}

export default Notifications;
import { connect } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import { setUserInfo } from "../../redux/actions";
import { signup } from "../../api/welcome";

function Signup({ dispatch }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [invalidText, setInvalidText] = useState("");
    const emailRef = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        setModalOpen(true);
        Modal.setAppElement("body");
    }, []);

    async function signupUser() {
        var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!email.trim().match(validEmailRegex)) {
            emailRef.current.focus();
            setInvalidText("Email is not valid");
            setTimeout(() => {
                setInvalidText("");
            }, 2000);
        } else if (username.trim() === "") {
            username.current.focus();
            setInvalidText("Username cannot be blank");
            setTimeout(() => {
                setInvalidText("");
            }, 2000);
        } else if (password === "") {
            username.current.focus();
            setInvalidText("Password cannot be blank");
            setTimeout(() => {
                setInvalidText("");
            }, 2000);
        } else {
            let resp = await signup(email, username, password);
            resp = resp.data;
            if (resp.result === "success") {
                dispatch(setUserInfo(resp.user_info));
                navigate("/search");
            } else {
                let dup = resp.result === "duplicate_email" ? "Email" : "Username";
                setInvalidText(dup + " already exists.");
                setTimeout(() => {
                    setInvalidText("");
                }, 2000);
            }
        }
    }

    const modalStyles = {
        content: {
            position: "absolute",
            height: "60%",
            width: "82%",
            transform: "translate(-50%, -50%)",
            top: "40%",
            left: "50%",
        }
    }

    return (
        <div style={{ position: "absolute", left: "50%", transform: "translateX: (-50%)" }}>
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => navigate("/")}
                style={modalStyles}
            >
                <Button variant="dark" onClick={() => navigate("/")} style={{ marginRight: "auto" }}>
                    <span aria-hidden="true">X</span>
                </Button>
                <h3 align="center" style={{ marginTop: "-20px" }}>Sign up to Iris</h3>
                <div>
                    <div>
                        <label>Email: </label><br />
                        <input className="form-control" ref={emailRef} type="text" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <br />
                    <div>
                        <label>Username: </label><br />
                        <input className="form-control" type="text" onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <br />
                    <div>
                        <label>Password: </label><br />
                        <input className="form-control" type="password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <br />
                    <Button className="form-control" onClick={signupUser} >Submit</Button>
                    <div style={{ marginTop: "10px" }}>
                        <span>Have an account already? </span><Link to="/login"><span>Log in</span></Link>
                    </div>
                    {invalidText !== "" && <h5 style={{ color: "red", marginTop: "5px" }}>{invalidText}</h5>}
                </div>
            </Modal>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        state
    };
};

export default connect(mapStateToProps)(Signup);
import { connect } from "react-redux";
import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import ProfilePosting from "../components/profile/ProfilePosting";
import { getProfileData, followProfile } from "../api/profile";
import { pinPosting, deletePosting, likePosting } from "../api/posting";

function Profile({ user_info }) {

    const routeParams = useParams();
    const isOwnProfile = user_info.username === routeParams.username;
    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [interests, setInterests] = useState([]);
    const [postings, setPostings] = useState([]);
    const [proPic, setProPic] = useState("");
    const [isFollowing, setIsFollowing] = useState(false);

    useEffect(() => {
        loadProfileData(user_info.user_id, routeParams.username);
    }, [user_info.user_id, routeParams.username]);

    async function loadProfileData(currentUserId, profileUsername) {
        let resp = await getProfileData(currentUserId, profileUsername);
        resp = resp.data;
        console.log('profile data  : ', resp);
        setFollowersCount(resp.followers_count);
        setFollowingCount(resp.following_count);
        setInterests(resp.interests);
        setPostings(resp.postings);
        setProPic(resp.pro_pic);
        setIsFollowing(resp.current_user_is_following);
    }

    async function reloadProfileData() {
        let resp = await getProfileData(user_info.user_id, routeParams.username);
        resp = resp.data;
        console.log('profile data  : ', resp);
        setFollowersCount(resp.followers_count);
        setFollowingCount(resp.following_count);
        setInterests(resp.interests);
        setPostings(resp.postings);
        setProPic(resp.pro_pic);
        setIsFollowing(resp.current_user_is_following);
    }

    async function handlePinPosting(postId, pinBool) {
        await pinPosting(postId, pinBool);
        reloadProfileData();
    }

    async function handleDeletePosting(postId) {
        await deletePosting(postId);
        reloadProfileData();
    }

    async function handleLikePosting(postId) {
        await likePosting(user_info.user_id, postId);
        reloadProfileData();
    }

    async function handleFollow() {
        await followProfile(user_info.user_id, routeParams.username);
        reloadProfileData();
    }

    return (
        <div className="col-sm-12">
            <div className="profile-header row">
                <img
                    className="profile-pro-pic"
                    src={proPic !== "" ? "data:image/jpeg;base64," + proPic : "/default_pro_pic.png"}
                    alt="..."
                ></img>
                <div className="col-sm-9" style={{ marginTop: "25px" }}>
                    <span
                        className="col-sm-12"
                        style={{ fontWeight: "bold", fontSize: "24px" }}
                    >
                        {routeParams.username}
                    </span>
                    <div
                        className="col-sm-12"
                        style={{ display: "flex" }}
                    >
                        <div className="col-sm-4">{followersCount} Followers</div>
                        <div className="col-sm-4">{followingCount} Following</div>
                        <div className="col-sm-4">{postings.length} Posts</div>
                    </div>
                </div>
            </div>
            {!isOwnProfile &&
                <Button onClick={handleFollow}>{isFollowing ? "Unfollow" : "Follow"}</Button>
            }
            <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-sm-12">
                    <h5 style={{ marginBottom: "0px" }}>Tags Following:</h5>
                    <div style={{ marginLeft: "2px" }}>
                        {interests.map((interest, i) => (
                            <Fragment key={interest.interest_id}>
                                {interest.interest_name}{interests.length - 1 !== i && ", "}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "15px" }}>
                <div className={postings.some((p) => p.is_pinned) ? "col-sm-8" : "col-sm-12"}>
                    <h5>Posts</h5>
                    {postings.map((posting) => (
                        <Fragment key={posting.post_id}>
                            <ProfilePosting
                                url={posting.url}
                                posting={posting}
                                isOwnPosting={isOwnProfile}
                                handlePinPosting={handlePinPosting}
                                handleDeletePosting={handleDeletePosting}
                                handleLikePosting={handleLikePosting}
                            />
                        </Fragment>
                    ))}
                </div>
                {postings.some((p) => p.is_pinned) &&
                    <div className="col-sm-4">
                        <h5>Pinned</h5>
                        {postings.filter((p) => p.is_pinned).map((posting) => (
                            <Fragment key={posting.post_id}>
                                <ProfilePosting
                                    url={posting.url}
                                    posting={posting}
                                    isOwnPosting={isOwnProfile}
                                    handlePinPosting={handlePinPosting}
                                    handleDeletePosting={handleDeletePosting}
                                    handleLikePosting={handleLikePosting}
                                    hideComments={true}
                                />
                            </Fragment>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(Profile);
import { useNavigate } from "react-router-dom";

function SearchMenuItem({ user }) {

    const navigate = useNavigate();

    return (
        <div
            key={user.user_id}
            className="search-menu-item"
            onClick={() => navigate("/profile/" + user.username)}
        >
            <h6>{user.username}</h6>
            <br />
            <label>{user.follow_count} followers</label>
        </div>
    );
}

export default SearchMenuItem;
import { post } from "./go";

export function signup(email, username, password) {
    var postObj = {
        mode: "signup",
        email,
        username,
        password
    };
    return post("/api/welcome/signup", postObj);
}

export function login(user, password) {
    var postObj = {
        mode: "login",
        user,
        password
    };
    return post("/api/welcome/login", postObj);
}
import { post } from "./go";

export function changeSettings(user_id, newEmail, newPassword, newProPic) {
    var formData = new FormData();
    formData.append("mode", "changeSettings");
    formData.append("user_id", user_id);
    formData.append("newEmail", newEmail);
    formData.append("newPassword", newPassword);
    formData.append("newProPic", newProPic);
    return post("/api/settings/settings", formData);
}
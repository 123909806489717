import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import URLPreview from "../posting/URLPreview";
import PopperHelper from "../Popper";
import { Button } from "react-bootstrap";
import { getURLPreview } from "../../api/posting";

function ProfilePosting({
    url,
    posting,
    isOwnPosting,
    handlePinPosting,
    handleDeletePosting,
    handleLikePosting,
    hideComments = false
}) {

    const [preview, setPreview] = useState({});
    const popperRef = useRef(null);

    useEffect(() => {
        loadPreview(url);
    }, [url]);

    async function loadPreview(site) {
        let resp = await getURLPreview(site);
        resp = resp.data;
        setPreview(resp.preview);
    }

    return (
        <div className="profile-posting" onClick={() => console.log('profile post clicked')}>
            <div className="row" style={{ marginTop: "4px", marginBottom: "5px", display: "flex" }}>
                <div className={hideComments ? "offset-8 col-sm-2" : "offset-10 col-sm-1"}>
                    <div className="profile-posting-likes">
                        {posting.likes}
                        <span
                            className={`like-icon ${posting.current_user_liked ? "active" : ""}`}
                        >
                            <i className="fas fa-xl fa-arrow-circle-up" onClick={() => handleLikePosting(posting.post_id)}></i>
                        </span>
                    </div>
                </div>
                {isOwnPosting &&
                    <div className="col-sm-1">
                        <PopperHelper
                            referenceElement={
                                <div className="options-button" onClick={(e) => e.preventDefault()}>
                                    {"..."}
                                </div>
                            }
                            ref={popperRef}
                            popperClassName="popper-options"
                            popperPlacement={hideComments ? "top" : "right"}
                            popperStyle={{ zIndex: 100 }}
                        >
                            <div className="options-menu">
                                <span
                                    className={`pin-icon ${posting.is_pinned ? "active" : ""}`}
                                    onClick={() => {
                                        handlePinPosting(posting.post_id, !posting.is_pinned);
                                        popperRef.current.close();
                                    }}
                                >
                                    <i className="fa-solid fa-thumbtack"></i>
                                </span>
                                <Button className="btn btn-danger" onClick={() => handleDeletePosting(posting.post_id)}>
                                    Delete
                                </Button>
                            </div>
                        </PopperHelper>
                    </div>
                }
            </div>
            <div className="row" style={{ marginRight: "3px" }}>
                <div className={hideComments ? "col-sm-12" : "col-sm-7"}>
                    <URLPreview
                        previewFor={"profilePosting"}
                        url={preview.url}
                        description={preview.description}
                        image={preview.image}
                        keywords={preview.keywords}
                        title={preview.title}
                    />
                </div>
                {!hideComments &&
                    <div className="col-sm-4">
                        <h5>Comments</h5>

                    </div>
                }
            </div>
            <div className="row">
                <div className="profile-posting-tags col-sm-12">
                    <h5 style={{ marginTop: "5px", marginRight: "8px", alignSelf: "center" }}>Tags</h5>
                    {posting.posting_interests.map((tag, i) => (
                        <div key={i} className="profile-posting-tag">
                            <span>{tag}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(ProfilePosting);
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import Home from "./Home";
import Search from "./Search";
import Notifications from "./Notifications";
import Messages from "./Messages";
import Profile from "./Profile";
import Settings from "./Settings";

function Main() {
    const location = useLocation();
    const routeParams = useParams();

    return (
        <div className="main-outer">
            <Navbar />
            <div className="main">
                {location.pathname === "/home" && <Home />}
                {location.pathname === "/search" && <Search />}
                {location.pathname === "/notifications" && <Notifications />}
                {location.pathname === "/messages" && <Messages />}
                {routeParams.username !== undefined && location.pathname === "/profile/" + routeParams.username && <Profile />}
                {location.pathname === "/settings" && <Settings />}
            </div>
        </div>
    );
}

export default (Main);
import { connect } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import CreatePosting from "./CreatePosting";

function Navbar({ user_info }) {

    const [postingModalOpen, setPostingModalOpen] = useState(false);

    const navigate = useNavigate();

    const profileNav = (
        <div className="navbar-profile" onClick={() => navigate("/profile/" + user_info.username)}>
            <img
                src={user_info.pro_pic !== "" ? "data:image/jpeg;base64," + user_info.pro_pic : "img/default_pro_pic.png"}
                style={{ width: "45px", height: "45px", border: "2px", borderRadius: "500px" }}
                alt=""
            ></img>
            <label className="navbar-profile-name">
                {user_info.username}
            </label>
        </div>
    );

    return (
        <>
            <div className="navbar-all">
                {profileNav}
                <div className="navbar-item" onClick={() => navigate("/home")}>
                    <i className="navbar-icon fas fa-house-chimney-window"></i>
                    <span className="navbar-text">Home</span>
                </div>
                <div className="navbar-item" onClick={() => navigate("/search")}>
                    <i className="navbar-icon fas fa-search"></i>
                    <span className="navbar-text">Search</span>
                </div>
                <div className="navbar-item" onClick={() => navigate("/notifications")}>
                    <i className="navbar-icon fa-solid fa-bell"></i>
                    <span className="navbar-text">Notifications</span>
                </div>
                <div className="navbar-item" onClick={() => navigate("/messages")}>
                    <i className="navbar-icon fas fa-envelope"></i>
                    <span className="navbar-text">Messages</span>
                </div>
                <div className="navbar-item" onClick={() => navigate("/settings")}>
                    <i className="navbar-icon fas fa-gear"></i>
                    <span className="navbar-text">Settings</span>
                </div>
                <div className="navbar-item" onClick={() => navigate("/")}>
                    <i className="navbar-icon fas fa-arrow-circle-left"></i>
                    <span className="navbar-text">Signout</span>
                </div>
                <div>
                    <Button
                        className="navbar-posting-button "
                        onClick={() => setPostingModalOpen(true)}
                    >
                        Post
                    </Button>
                </div>
                <CreatePosting
                    modalOpen={postingModalOpen}
                    setModalOpen={setPostingModalOpen}
                />
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(Navbar);
import { get, post } from "./go";

export function getProfileData(currentUserId, profileUsername) {
    var getObj = {
        mode: "getProfileData",
        currentUserId,
        profileUsername
    };
    return get("/api/profile/profile", { params: getObj });
}

export function followProfile(currentUserId, profileUsername) {
    var postObj = {
        mode: "followProfile",
        currentUserId,
        profileUsername
    };
    return post("/api/profile/profile", postObj);
}
import { connect } from "react-redux";
import { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Modal from "react-modal";
import { TailSpin } from "react-loader-spinner";
import { Typeahead } from "react-bootstrap-typeahead";
import URLPreview from "../posting/URLPreview";
import { getInterestsOptions, getURLPreview, createPosting } from "../../api/posting";

function CreatePosting({ user_info, modalOpen, setModalOpen }) {

    const [preview, setPreview] = useState({});
    const [previewError, setPreviewError] = useState(false);
    const [createPostingError, setCreatePostingError] = useState("");
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [interestsOptions, setInterestsOptions] = useState([]);
    const [interestsSelections, setInterestsSelections] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isPinned, setIsPinned] = useState(false);
    const [activeIndex, setActiveIndex] = useState(-1);
    const modalRef = useRef(null);
    const fromRef = useRef(null);
    const typeaheadRef = useRef(null);
    const navigate = useNavigate();

    const [postingSuccessTemp, setPostingSuccessTemp] = useState(false);

    useEffect(() => {
        setModalOpen(modalOpen);
        Modal.setAppElement("body");
    });

    async function handleCreatePosting() {
        if (preview.url === undefined) {
            setCreatePostingError("URL invalid.");
            setTimeout(() => {
                setCreatePostingError("");
            }, 3000);
        } else if ([...interestsSelections].length === 0) {
            setCreatePostingError("At least 1 tag required.");
            setTimeout(() => {
                setCreatePostingError("");
            }, 3000);
        } else {
            var is_pinned = isPinned;
            var tags = [...interestsSelections];
            let resp = await createPosting(user_info.user_id, preview.url, is_pinned, tags);
            if (resp.data.success) {
                setPostingSuccessTemp(true);
                setTimeout(() => {
                    setPostingSuccessTemp(false);
                    onModalClose();
                    navigate("/profile/" + user_info.username);
                }, 500);
            }
        }
    }

    async function loadInterestsOptions() {
        let resp = await getInterestsOptions();
        let tempInterestsOptions = resp.data.interests_options.map((i) => i.interest_name);
        setInterestsOptions(tempInterestsOptions);
    }

    async function handleURLBlur(e) {
        let blurURL = e.target.value;
        if (preview.url !== undefined && preview.url === blurURL) return;
        if (blurURL !== "") {
            setPreview({});
            setLoadingPreview(true);
            let resp = await getURLPreview(blurURL);
            resp = resp.data;
            setLoadingPreview(false);
            if (resp.success) {
                setPreview(resp.preview);
            } else {
                setPreviewError(true);
            }
        } else {
            setPreview({});
        }
    }

    const handleSearchEnter = useCallback(
        (e) => {
            if (e.keyCode === 13 && activeIndex === -1) {
                let newInterestsSelections = [...interestsSelections, searchText];
                setInterestsSelections(newInterestsSelections);
                setSearchText("");
                typeaheadRef.current.clear();
            }
        },
        [activeIndex, searchText, interestsSelections]
    );

    const ActiveIndexWatcher = ({ update }) => {
        useEffect(update);
        return null;
    };

    function onModalClose() {
        setModalOpen(false)
        setPreviewError(false);
        setIsPinned(false);
        setPreview({});
        setInterestsSelections([]);
    }

    const modalStyles = {
        content: {
            position: "absolute",
            minHeight: "200px",
            maxHeight: "700px",
            height: preview.url !== undefined ? "700px" : "350px",
            overflow: "hidden",
            width: "82%",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            borderRadius: "32px"
        }
    }

    return (
        <Modal
            isOpen={modalOpen}
            onAfterOpen={loadInterestsOptions}
            onAfterClose={onModalClose}
            style={modalStyles}
        >
            <div ref={modalRef}>
                <Button variant="dark" onClick={() => setModalOpen(false)} style={{ marginRight: "auto", position: "relative", zIndex: "1" }}>
                    <span aria-hidden="true">X</span>
                </Button>
                <h4 style={{ textAlign: "center", position: "relative", bottom: "25px", marginBottom: "-15px" }}>
                    Create post!
                </h4>
                <label>URL: </label><br />
                <input className="form-control" type="text"
                    onBlur={handleURLBlur}
                    onFocus={() => setPreviewError(false)}
                    disabled={loadingPreview}
                />
                <TailSpin
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    visible={loadingPreview}
                />
                {preview.url !== undefined &&
                    <URLPreview
                        previewFor={"createPosting"}
                        url={preview.url}
                        description={preview.description}
                        image={preview.image}
                        keywords={preview.keywords}
                        title={preview.title}
                    />
                }
                {previewError &&
                    <span style={{ color: "red" }}><br />URL preview not found<br /></span>
                }
                {preview.url !== undefined &&
                    <div className="create-posting-pin">
                        <span
                            className={`pin-icon ${isPinned ? "active" : ""}`}
                            onClick={() => setIsPinned(!isPinned)}
                        >
                            <i className="fa-solid fa-thumbtack"></i>
                        </span>
                        {isPinned && <label>pin post</label>}
                    </div>
                }
                <Typeahead
                    id={"posting-typeahead"}
                    labelKey={"interest_name"}
                    ref={typeaheadRef}
                    options={interestsOptions}
                    placeholder={"Add tags"}
                    selected={interestsSelections}
                    onChange={setInterestsSelections}
                    multiple
                    minLength={3}
                    onInputChange={(text) => {
                        setSearchText(text);
                        fromRef.current = text;
                    }}
                    onKeyDown={handleSearchEnter}
                    style={{ marginTop: "20px" }}
                    disabled={loadingPreview}
                />
                <ActiveIndexWatcher update={() => setActiveIndex(activeIndex)} />
                {createPostingError !== "" && <span style={{ color: "red" }}><br />Post can't be created. {createPostingError}<br /></span>}
                {postingSuccessTemp && <h3 style={{ color: "green", marginTop: "50px" }}>Post Created!</h3>}
                <Button className="posting-modal-posting-button" onClick={handleCreatePosting} disabled={loadingPreview}>
                    Post
                </Button>
            </div>
        </Modal>
    );
}

const mapStateToProps = (state) => {
    return {
        user_info: state.user_info
    };
};

export default connect(mapStateToProps)(CreatePosting);
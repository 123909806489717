import { connect } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Button } from "react-bootstrap";
import { setUserInfo } from "../../redux/actions";
import { login } from "../../api/welcome";

function Login({ dispatch }) {
    const [modalOpen, setModalOpen] = useState(false);
    const [user, setUser] = useState("");
    const [password, setPassword] = useState("");
    const [isWrongLogin, setIsWrongLogin] = useState(false);
    const navigate = useNavigate();
    const userRef = useRef();

    useEffect(() => {
        setModalOpen(true);
        Modal.setAppElement("body");
    }, []);

    async function loginUser() {
        var resp = await login(user, password);
        resp = resp.data;
        if (resp.result === "success") {
            dispatch(setUserInfo(resp.user_info));
            navigate("/search");
        } else {
            setIsWrongLogin(true);
            userRef.current.focus();
            setTimeout(() => {
                setIsWrongLogin(false);
            }, 5000);
        }
    }

    const modalStyles = {
        content: {
            position: "absolute",
            height: "60%",
            width: "82%",
            transform: "translate(-50%, -50%)",
            top: "40%",
            left: "50%",
        }
    }

    return (
        <>
            <div style={{ position: "absolute", left: "50%", transform: "translateX: (-50%)" }}>
                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => navigate("/")}
                    style={modalStyles}
                >
                    <Button variant="dark" onClick={() => navigate("/")} style={{ marginRight: "auto" }}>
                        <span aria-hidden="true">X</span>
                    </Button>
                    <h3 align="center" style={{ marginTop: "-20px" }}>Sign in to Iris</h3>
                    <div>
                        <div>
                            <label>Email/Username: </label><br />
                            <input className="form-control" ref={userRef} type="text" onChange={(e) => setUser(e.target.value)} />
                        </div>
                        <br />
                        <div>
                            <label>Password: </label><br />
                            <input className="form-control" type="password" onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <br />
                        <Button className="form-control" onClick={loginUser} >Submit</Button>
                        <div style={{ marginTop: "10px" }}>
                            <span>Don't have an account? </span><Link to="/signup"><span>Sign up</span></Link>
                        </div>
                        {isWrongLogin && <h5 style={{ color: "red", marginTop: "25px" }}>Cannot find account</h5>}
                    </div>
                </Modal>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        state
    };
};
export default connect(mapStateToProps)(Login);
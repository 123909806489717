import { get, post } from "./go";

export function createPosting(user_id, url, is_pinned, tags) {
    var postObj = {
        mode: "createPosting",
        user_id,
        url,
        is_pinned,
        tags
    };
    return post("/api/posting/posting", postObj);
}

export function pinPosting(postId, pinBool) {
    var postObj = {
        mode: "pinPosting",
        postId,
        pinBool
    };
    return post("/api/posting/posting", postObj);
}

export function deletePosting(postId) {
    var postObj = {
        mode: "deletePosting",
        postId
    };
    return post("/api/posting/posting", postObj);
}

export function likePosting(currentUserId, postId) {
    var postObj = {
        mode: "likePosting",
        currentUserId,
        postId
    };
    return post("/api/posting/posting", postObj);
}

export function getURLPreview(url) {
    var getObj = {
        mode: "getURLPreview",
        url
    };
    return get("/api/posting/posting", { params: getObj });
}

export function getInterestsOptions() {
    var getObj = {
        mode: "getInterestsOptions"
    };
    return get("/api/posting/posting", { params : getObj });
}